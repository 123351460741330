@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100;0,400;0,500;0,600;1,200&display=swap');

*{
  margin: 0;
  padding: 0;
}
.body {
  background-color: #fff;
}



.titulo {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.5em;
}

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  /*background-image: url("./fondo-transmisiones-amcg-c.jpg");*/
  background-color: rgb(10, 42, 77);
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
}

.headertitulo {
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #16328ad6;
  margin: 100px 20px 100px 20px;
}

.logoh {
  padding: 20px;
  max-width: 300px;
}

.logoh2 {
  padding: 20px;
  max-width: 70px;
  display: block;
  margin: auto;
  justify-content: center;
}

.nombretransmision {
  color: #fff;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 2em;
  font-weight: 500;
  text-align: left;
}

.fechatransmision {
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  font-weight: 400;
  text-align: left;
}

.headertexto {
  max-width: 700px;
  margin: 20px 20px;
}

.link{
  font-family: 'Montserrat Alternates', sans-serif;
  text-align: center;
}

.videoseccion {
 
  background-image: url("./about_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 100px;
  justify-items: center;
  vertical-align: top;
  padding-bottom: 100px;
}

.video {
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;

  
}

.embed-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  padding-top: 42px;
}

.col2 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  
}

.tab-content {
  width: 100%;
  background-color: #fff;
  border: solid;
  border-color: #2E69AA;
  border-width: 0px 2px 2px 2px;
  padding-top: 50px;
}

.tab-list {
  border-bottom: 2px solid #2E69AA;
  padding-left: 0;
  text-align: center;
  color: #2e69aa;
 font-weight: 400;
 padding-bottom: 0;
 
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
}

.tab-list-active {
  background-color: white;
  border: solid #2E69AA;
  border-width: 2px 2px 0 2px;
  text-align: center;
  font-weight: 900;
}

.titulotabs {
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}

.horatabs {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
  
}

.titulopresentacion {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 900;
  text-align: center;  
}

.nombredoctores {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  text-align: center;
  font-style: italic;
  
}
.lista{
  padding-bottom: 20px;
}

.textotabs {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  text-align: center;
  padding: 20px;
}

.urltab {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
  color: blue;
}

.listatabs {
  list-style: none;
  padding-left: 0;
}

/*Chat*/
.container {
  display: flexbox;
  flex-wrap: wrap;
  width: 100%;
  height: 20em;
}

@media (max-width: 1380px) {
  
  
  .videoseccion {
   padding-top: 50px;
   padding-bottom: 50px;
  }

  .nombretransmision {
    color: #fff;
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 2em;
    font-weight: 500;
    text-align: center;
  }
  .fechatransmision {
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 1em;
    font-weight: 400;
    text-align: center;
  }
 
}

.seccion{
  display: flex;
    flex-direction: column;
}

.col {
  flex: 1 0;
  margin: 0;
  padding: 0;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 0;
}

/*banner constancias*/

.constancia{
 background-color: #2E69AA;
 padding-top: 20px;
 padding-bottom: 60px;
 padding-left: 10px;
 padding-right: 10px;
}
.titulocons{
  padding-top: 20px;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 2em;
  font-weight: 600;
  text-align: center;
  
}
.textocons{
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  font-weight: 500;
  text-align: center;

}
.col-centered{
  display:flex;
  justify-content:center;
  align-items: center;
  padding-bottom: 30px;
}

.ImageVideo{
  width: 80%;
  margin: 0 10%;
}

.ocultar{
  display: none !important;
}

.channel a button {
  margin: 20px;
  font-size: 13px;
}